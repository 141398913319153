@import '../../styles/colors';
@import '../../styles/media';

.header {
  display: flex;
  justify-content: space-between;
  background: $white;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media all and (max-width: $maxXs) {
  .header {
    padding: 0 0.5rem !important;
  }
}

.menuContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.menu {
  border: none;
}

.menuItem {
  height: 100%;
}

.menuItemTitle {
  margin-bottom: 0 !important;
  font-size: 1rem !important;
  line-height: calc(4rem - 4px);

  &:hover {
    color: $primary-color !important;
  }
}
.burgerMenu {
  .menuItem {
    height: 100%;
  }

  .menuItemTitle {
    margin-bottom: 0 !important;
    font-size: 1rem !important;
    line-height: calc(4rem - 4px);
    color: $white !important;
    &:hover {
      color: $primary-color !important;
    }
  }
}

.uvmLogo {
  padding-top: 0 !important;
}
