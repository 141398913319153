@import '../styles/colors';
@import '../styles/media';

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -1rem;
}
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: $white;
  padding: 1rem 0 0;
  border: none;
}

.card-container
  > .ant-tabs-card
  > .ant-tabs-content
  > .ant-tabs-tabpane-inactive {
  border: none;
}

@media all and (min-width: 611px) {
  .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    padding: 1rem;
    border: 1px solid $border-color;
    border-top: none;
  }

  .card-container
    > .ant-tabs-card
    > .ant-tabs-content
    > .ant-tabs-tabpane-inactive {
    border: none;
  }
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-radius: 0;
  border-color: $white;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-radius: 0;
  border-color: $border-color;
  background: $whiteish;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-radius: 0;
  border-color: $border-color;
  border-bottom-color: $white;
  background: $white;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab:last-child {
  margin-right: 0 !important;
}

/* Base button styles */
.ant-btn:not(.ant-btn-circle) {
  font-size: 14px;
  padding: 0 1.5rem;
  font-weight: 600;
  &:not(:hover) {
    border-color: transparent;
  }
}

/* Default button styles */
.ant-btn.ant-btn-default:not(:disabled) {
  background: $gray-blue;
  color: $text-65-color;
}

.ant-avatar-image {
  background-color: #ccc;
}
.ant-modal-confirm {
  .ant-modal-confirm-body {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    text-align: center;
    .ant-modal-confirm-content {
      margin-left: 0 !important;
    }

    i.anticon {
      margin-bottom: 1rem;
      font-size: 2rem;
      color: $primary-color !important;
    }
  }

  .ant-modal-confirm-btns {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    float: none;
  }
}
.ant-btn-circle.ant-btn-lg,
.ant-btn-circle-outline.ant-btn-lg {
  min-width: 40px !important;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 30px;
  top: 1rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $primary-color;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $text-65-color;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $primary-color;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(0, 0, 0, 0.85);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0) !important;
}
@media screen and (max-width: $maxXs) {
  .ant-list-item-action {
    margin: auto;
    margin-top: 0.5rem;
  }
}
