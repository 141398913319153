$white: #ffffff;
$whiteish: #fafafa;
$cloud: #efefef;
$gray-blue: #f0f2f5;
$border-color: #e8e8e8;
$disabled-text: #b8b8b8;
$trouble-yellow: #c4c115;
$problem-red: #d64811;
$success-green: #0aa024;
$primary-color: #4b7f8d;
$text-65-color: rgba(0, 0, 0, 0.65);
$text-base-color: rgba(0, 0, 0, 0.85);
$text-grey-color: #908d8d;
