@import '../../../styles/colors';

.container {
  background: $primary-color;
  padding: 0.75rem;
  height: 120%;
  display: flex;
  align-items: flex-start;
  line-height: normal;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.username {
  color: $white;
  margin-bottom: 0 !important;
  max-width: 7rem;
}

.logud {
  color: $white;
  text-decoration: underline;
  cursor: pointer;
}

.minprofil {
  color: $white;
  text-decoration: underline;
}

.avatarContainer {
  padding-left: 1rem;
}
