@import '../../../styles/colors';

.brand {
  display: flex;
  align-items: center;
}

.logo {
  height: 4rem;
  padding-top: 0.5rem;
  max-width: 14rem;
}

.title {
  padding-left: 0.5rem;
  margin-bottom: 0 !important;
  display: inline-block;

  a {
    color: $text-base-color;
  }
}
