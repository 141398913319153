@import '../../styles/colors';
@import '../../styles/media';

@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/fonts/OpenSans/OpenSans-SemiBold.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/fonts/OpenSans/OpenSans-Light.ttf');
  font-weight: 400;
  font-style: normal;
}
.jumbotron {
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-size: cover !important;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}

.title {
  color: $white !important;
  font-weight: 500 !important;
}

.subtitle {
  color: $white !important;
  margin-top: 0 !important;
  margin-bottom: 2rem !important;
  font-weight: 400 !important;
  white-space: pre-wrap;
}

.search {
  width: 300px;
  input {
    border-radius: 32px;
  }
}

.display {
  color: $white !important;
  font-size: 4rem !important;
  font-weight: 300 !important;
  text-align: center;
}

@media all and (max-width: $maxXs) {
  .display {
    font-size: 3rem !important;
  }
}
