@import '../../styles/colors';
@import '../../styles/media';

.footer {
  background: $white;
  padding: 2rem 0;
  margin-top: 15rem;
}

.externalFooter {
  margin-top: 0;
  .linksContainer {
    background: $cloud;
    padding: 2rem 9rem;
  }
}

@media all and (max-width: $maxXs) {
  .footer {
    padding: 1.5rem 1rem !important;
  }
}

.list {
  margin-top: 1em;
  text-align: left;
  list-style: none;
  padding: 0;
}
.logo {
  width: 100%;
}

.linksContainer {
}

.contactInfoContainer {
  padding: 2rem 9rem;
}
.contactInfoContainerInternal {
  padding: 2rem 0;
}

@media all and (max-width: $maxMd) {
  .contactInfoContainer,
  .externalFooter .linksContainer {
    padding: 2rem 4rem;
  }
}

@media all and (max-width: $maxXs) {
  .contactInfoContainer,
  .externalFooter .linksContainer {
    padding: 1rem;
  }
}
